import React from 'react'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Section from 'components/section'

import Meta from 'components/meta'
import Wrapper from 'components/wrapper'

interface Props {
  location: Location
}

const NotFound = ({ location }: Props) => {
  return (
    <>
      <Meta title="Not Found" />
      <Wrapper location={location} className="d-flex w-100 h-100 mx-auto flex-column">
        <Section className="py-5">
          <Row className="justify-content-center align-items-center text-center">
            <Col xs={12}>
              <h1 className="display-6">Not Found</h1>
              <p className="lead mb-2">Sorry, can&apos;t find it. Perhaps a broken link?</p>
              {false && (
                <>
                  <p className="lead">Try one of these...</p>
                  <Row className="g-2 justify-content-center text-nowrap">
                    <Col className="flex-grow-0">
                      <Button href={'/#projects'} variant="secondary">
                        Projects
                      </Button>
                    </Col>
                    <Col className="flex-grow-0">
                      <Button href={'/#support'} variant="secondary">
                        Support
                      </Button>
                    </Col>
                    <Col className="flex-grow-0">
                      <Button href={'/#contact'} variant="secondary">
                        Contact
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Section>
      </Wrapper>
    </>
  )
}

export default NotFound
